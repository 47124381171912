<template>
  <div class="subnavigation">
    <slot></slot>
  </div>
</template>

<style scoped>
.subnavigation {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: sofia-pro, sans-serif, Helvetica Neue, Helvetica, Arial;
  font-size: 1rem;
  line-height: 1.875rem;
 
  background-image: linear-gradient(-44deg, #e55801cc, #f38800);
  width: 100%;
}
</style>
